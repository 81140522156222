<template>
  <div class="rank-wrapper">
    <div class="rank-wrapper-inner">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rank-wrapper-content">
              <div class="rank-icon">
                <div class="rank-icon__icon" />
                <div class="rank-icon__text">
                  {{ $t(crownText) }}
                </div>
              </div>
              <h3 :id="anchorTitle" class="rank-wrapper-title">
                <template v-if="title === 'platinum executive'">
                  {{ $tc((title).toLowerCase()) | upperFirst }}
                </template>
                <template v-else>
                  {{ $t((title + 's').toLowerCase()) | upperFirst }}
                </template>
              </h3>
              <div class="rank-wrapper-block">
                <div class="rank-wrapper-tr-text-block">
                  <div class="rank-wrapper-tr-text">
                    {{ backgroundTitle }}
                  </div>
                </div>
                <slot />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    backgroundTitle () {
      return this.title.split(' ').length === 3
        ? this.title.split(' ').reverse().slice(1).reverse().join(' ')
        : this.title
    },
    crownText () {
      return this.$t(this.title.split(' ').map(e => e.substring(0, 1)).join(''))
    },
    anchorTitle () {
      return this.title.split(' ').join('_')
    }
  }
}
</script>
<style lang="scss">
.rank-wrapper-content {
  padding-top: 140px;
  position: relative;
}

.rank-wrapper-title {
  margin-top: 20px;
  font-size: 46px;
  font-weight: bold;
  line-height: 1.1;
  letter-spacing: -0.02em;
  position: relative;
  z-index: 2;
  white-space: pre-line;
  text-transform: capitalize;
}

.rank-wrapper-block {
  position: relative;
  z-index: 1;
  padding-top: 10px;
}

.rank-wrapper-tr-text-block {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: -90px;
}

.rank-wrapper-tr-text {
  line-height: 1;
  font-family: "BebasNeuePro", sans-serif;
  font-weight: bold;
  font-style: italic;
  white-space: nowrap;
  font-size: 300px;
  text-transform: uppercase;
  color: #fff;
  user-select: none;
  -webkit-text-stroke: 1px rgba(#fff, 0.3);
  -webkit-text-fill-color: transparent;
}

.rank-icon {
  width: 60px;
  height: 54px;
  position: relative;
  z-index: 2;

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 54px;
    background-image: var(--crown-full);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60px 54px;
  }

  &__text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
  }
}

@media (max-width: 1480px) {
  .rank-wrapper-tr-text {
    font-size: 240px;
  }

  .rank-wrapper-tr-text-block {
    top: -60px;
  }
}

@media (max-width: 1264px) {
  .rank-wrapper-tr-text {
    font-size: 190px;
  }

  .rank-wrapper-content {
    padding-top: 100px;
  }
}

@media (max-width: 960px) {
  .speaker-slider-block {
    margin-top: 70px;
  }

  .rank-wrapper-content {
    padding-top: 70px;
  }
}

@media (max-width: 600px) {
  .rank-wrapper-inner {
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
  }

  .speaker-slider-block {
    margin-top: 50px;
  }

  .rank-wrapper-content {
    padding-top: 50px;
  }

  .rank-icon {
    width: 35px;
    height: 32px;

    &__icon {
      width: 35px;
      height: 32px;
      background-size: 35px 32px;
    }

    &__text {
      display: none;
    }
  }

  .rank-wrapper-title {
    font-size: 28px;
  }

  .rank-wrapper-tr-text-block {
    justify-content: center;
    top: -40px;
    width: 100%;
  }

  .rank-wrapper-tr-text {
    font-size: 140px;
  }
}

</style>
