<template>
  <div class="speaker-wrapper">
    <div class="speaker-item" :class="{ 'speaker-item--horizontal' : isGold }">
      <div class="speaker-item__img-block">
        <router-link :to="`/profile-nominee/${master.slug}`" class="speaker-item__img">
          <Avatar
            v-if="isGold && isScreen1400"
            :src="master.photo"
            tile
          />
          <Avatar
            v-else
            :src="master.photo && getLinkByFileName('ranks', master.photo)"
            tile
          />
        </router-link>
      </div>
      <div class="speaker-item__content">
        <div class="speaker-item__name">
          <router-link :to="`/profile-nominee/${master.slug}`">
            {{ master.name }}
          </router-link>
        </div>
        <div v-if="master" class="speaker-item__location">
          {{ master.country }}, {{ master.city }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar'
import { getLinkByFileName } from '@/utils/mediaHelpers'
import store from '../store'

export default {
  components: {
    Avatar
  },
  store,
  props: {
    master: {
      type: Object,
      required: true
    },

    isGold: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isScreen1400 () {
      return window.screen.width > 1260
    }
  },
  methods: {
    getLinkByFileName,
    handleError (e) {
      e.target.src = getLinkByFileName('/assets/img/theme/person-plug.png')
    }
  }
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/variables";

.speaker-item {
  max-width: 230px;
  margin-right: auto;
  margin-left: auto;

  &--horizontal {
    max-width: 689px;
    width: 689px;
  }

  @media screen and (max-width: 1455px) {
    &--horizontal {
      max-width: 650px;
      width: 650px;
    }
  }

  @media screen and (max-width: 1400px) {
    &--horizontal {
      max-width: 589px;
      width: 589px;
    }
  }

  @media screen and (max-width: 1260px) {
    &--horizontal {
      max-width: 230px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .speaker-slider.speaker-slider--gold {
    margin-right: -17px;
  }
}

.speaker-item__img-block {
  position: relative;
}

.speaker-item__img {
  height: 408px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: block;
}

.speaker-item__img::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-image: linear-gradient(0deg, #202020 0%, rgba(29, 29, 29, 0) 46.9%);
  opacity: 0.6;
}

.speaker-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.speaker-item__content {
  padding-top: 20px;
}

.speaker-item__name {
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: bold;
  color: #252525;
  line-height: 1.2;
}

.speaker-item__name a {
  color: #252525;
  text-decoration: none;
}

.speaker-item__location {
  line-height: 1.4;
  margin-top: 10px;
}

.speaker-list-item--small-img {
  .speaker-item__img {
    height: 230px;
  }
}

@media (max-width: 600px) {
  .speaker-item__location {
    font-size: 14px;
  }

  .speaker-item__soc-links {
    display: none;
  }
}
</style>
