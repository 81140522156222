<template>
  <v-container>
    <div class="speaker-slider-block">
      <Slick
        ref="carousel"
        :options="sliderSettings"
        class="speaker-slider slider"
        :class="{'speaker-slider--gold' : isGold}"
      >
        <div
          v-for="(item, idx) in masters"
          :key="''+idx + item.name"
          class="speaker-slider slide"
        >
          <Card :is-gold="isGold" :master="item" />
        </div>
      </Slick>
    </div>
  </v-container>
</template>

<style lang="scss">
button {
  outline: none !important;
}

.slide:first-child {
  display: block;
}

.slick-slide {
  outline: none !important;
}

.slick-slider {
  padding-left: 96px;
  padding-right: 96px;

  .slick-prev,
  .slick-next {
    width: 76px;
    height: 76px;
    padding: 0;
    border-radius: 20px;
    cursor: pointer;
    z-index: 4;
    background-color: #fff;
    position: absolute;
    border: 1px solid #252525;
    top: 50%;
    margin-top: -38px;
  }

  .slick-prev::before,
  .slick-next::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 16px 16px;
    transition: 0.4s ease;
    background-color: #252525;
  }

  .slick-prev {
    left: 0;

    &::before {
      mask-image: var(--slider-arrow-left);
    }
  }

  .slick-next {
    right: 0;

    &::before {
      mask-image: var(--slider-arrow-right);
    }
  }

  .slick-prev:hover,
  .slick-next:hover {
    background-color: #D156FA;
    border-color: #D156FA;
  }

  .slick-prev:hover::before,
  .slick-next:hover::before {
    background-color: #D156FA;
  }
}

.speaker-slider-block {
  margin-top: 100px;
}

.speaker-slider.slick-slider {
  .slick-slide {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    .speaker-item {
      text-align: left;
      margin-top: 0;
    }
  }

  .slick-prev,
  .slick-next {
    top: 205px;
    background-color: #8440D3;
    border: 1px solid #8440D3;
  }

  .slick-prev::before,
  .slick-next::before {
    background-color: #fff;
  }

  .slick-prev:hover,
  .slick-next:hover {
    background-color: #88D9FD;
    border-color: #88D9FD;
  }

  .slick-prev:hover::before,
  .slick-next:hover::before {
    background-color: #fff;
  }
}

.speaker-slider.slick-slide {
  .slick-track {
    transform: translate3d(0, 0, 0) !important;
  }
}

@media screen and (max-width: 560px) {
  .speaker-slider--gold {
    .slick-track {
      transform: unset;
    }
  }
}

.speaker-slide {
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1264px) {
  .speaker-slider-block {
    margin-top: 70px;
  }
}

@media (max-width: 768px) {
  .slick-slider {
    padding-left: 0;
    padding-right: 0;

    .slick-list {
      overflow: hidden;
    }
  }
}

</style>

<script>
import Slick from 'vue-slick'
import Card from './Card'

const staticGoldSpeakers = {
  'aisha-ahmedova-gold-executive': require('@/assets/img/theme-forum-22/aisha_ahmedova_cart213.jpg'),
  'ekaterina-khancha-gold-executive': require('@/assets/img/theme-forum-22/ekaterina_khancha_cart130.jpg')
}

export default {
  components: {
    Slick,
    Card
  },

  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    rankType: {
      type: String,
      default: ''
    }
  },

  computed: {
    isGold () {
      return this.rankType === 'gold'
    },

    sliderSettings () {
      return {
        slidesToShow: this.isGold ? 2 : 4,
        arrows: false,
        variableWidth: this.isGold,
        dots: false,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
          {
            breakpoint: 1903,
            settings: {
              arrows: true,
              slidesToShow: this.isGold ? 2 : 3
            }
          },
          {
            breakpoint: 1264,
            settings: {
              arrows: true,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 992,
            settings: {
              arrows: true,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              // variableWidth: true,
              // centerMode: true,
              slidesToShow: 2
            }
          },
          {
            breakpoint: 560,
            settings: {
              arrows: false,
              // variableWidth: true,
              // centerMode: true,
              slidesToShow: 1
            }
          }
        ]
      }
    },

    masters () {
      if (this.isGold && window.screen.width > 1260) {
        return this.items.map(i => ({ ...i, photo: staticGoldSpeakers[i.slug] }))
      }
      return this.items
    }
  },

  watch: {
    items () {
      const currIndex = this.$refs.carousel.currentSlide()

      this.$refs.carousel.destroy()
      this.$nextTick(() => {
        this.$refs.carousel.create()
        this.$refs.carousel.goTo(currIndex, true)
      })
    }
  }
}
</script>
